import React from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import api from '../../services/api';

import './styles.css';
import PostLink from '../PostLink';

export const SliderHome = ({posts}) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    arrows: false,
    adaptiveHeight: true,
    pauseOnHover: true
  };
  
  return (
    <Slider {...settings} className="sliderRight sliderTop">
      {posts.map((post,index) => (            
        <div key={index} className="boxSlider">
          {post.pics.length > 0 &&
            <PostLink post={post}>
              <img src={post.pics[0].pic_v} alt={post.title} />
            </PostLink>
          }
          <PostLink post={post}>
            <h3>{post.title}</h3>
          </PostLink>
          <p>{post.description}</p>
        </div>
      ))}
    </Slider>
  );
}

export default SliderHome;