import React from 'react';

import Tag from '../Tag';
import PostLink from '../PostLink';
import { Col, Row } from 'react-bootstrap';

export const Firsts = ({posts}) => {
    return (
        <Row>
            {posts.map((post,index) => (
              <Col key={index} sm={6} xs={12}>
                <div className="boxNotice small">
                  <div className="boxTopNotice">
                    <Tag tags={post.tags} />
                    <div className="dateNotice">
                        <span>{post.shortdate}</span>
                    </div>
                  </div>
                  <PostLink post={post}>
                    <h1>{post.title}</h1>
                  </PostLink>
                  <p>{post.description}</p>
                </div>
              </Col>
            ))}
        </Row>
    );
}

export default Firsts;