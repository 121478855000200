import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { RiDownloadLine } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';

import api from '../../services/api';

export default class Galeria extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      images: [],
      page: 0,
      prevY: 0,
      hasMore: true
    };
  }

  componentDidMount() {
    this.getImages();
  }

  getImages = () => {
    this.setState({ loading: true });

    let start = (4 * this.state.page) + this.state.page;
    let end = start + 4;    
    
    api(`/getposts.php?fullurl&slice=Galeria&start=${start}&end=${end}`).then(res => {
      let images = this.state.images;

      res.data.posts.map(post => {
        post.pics.map(pic => {
          images.push(pic);
        });
      });

      let hasMore = res.data.posts.length > 0;
      
      this.setState({ images, loading: false, page: this.state.page + 1, hasMore });
    });
    
  }

  render() {
    return (
      <div className="container">
        <InfiniteScroll
          dataLength={this.state.images.length}
          next={this.getImages}
          hasMore={this.state.hasMore}
          loader={<p style={{ textAlign: 'center' }}><b>Carregando...</b></p>}
          refreshFunction={this.getImages}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
        >
          <Row>
            {this.state.images.map((i,index) => (
              <Col key={index} sm={6} xs={12}>
                <div key={index} className="sliderGallery">
                  <img src={i.pic_h} alt={i.caption} />
                  <div className="downloadImg">
                    <a href={i.fullpic_h} target="_blank" download>
                      <RiDownloadLine />
                      DOWNLOAD
                    </a>
                  </div>
                  <p><b>Legenda:</b> {`${i.caption}`}</p>
                  <p><b>Créditos:</b>{`${i.credits}`}</p>
                </div>
              </Col>
            ))}
          </Row>
        </InfiniteScroll>
      </div>
    );
  }
}  