import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import News from './pages/News';
import Search from './pages/Search';
import Result from './pages/Result';
import Galeria from './pages/Galeria';
import Boletim from './pages/Boletim';
import MoreImages from './pages/MoreImages';

const Routes = () => (
    <BrowserRouter basename="/">
        <Header />
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/noticia/:slug" component={News} />

            <Route exact path="/boletins/:boletim" component={Boletim} />
            <Route exact path="/boletins/" component={Boletim} />

            <Route exact path="/galeria" component={Galeria} />
            <Route exact path="/galeria/vermais/:slug" component={MoreImages} />

            <Route exact path="/:param/:term" component={Result} />
            <Route exact path="/:param" component={Result} />
            
        </Switch>
        <Footer />
    </BrowserRouter>
    
)

export default Routes;