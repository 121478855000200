import React from 'react';

import { Col, Row } from 'react-bootstrap';

import './styles.css';

export const Videos = ({posts}) => {
  return (
    <>
      <div className="box-titles">
          <h1 className="title active">VÍDEOS</h1>
      </div>
      <Row>
        {posts.map((post,index) => (
          <Col key={index} sm={3}>
            <div dangerouslySetInnerHTML={{__html: post.contents}} />
          </Col>
        ))}
      </Row>
      <a id="allVideos" href="https://www.youtube.com/user/prefeiturasaopaulo/videos" target="_blank">TODOS OS VÍDEOS</a>
    </>
  );
}

export default Videos;