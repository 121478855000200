import React from 'react';

import Marquee from "react-fast-marquee";

import './styles.css';

export const SliderHeader = (props) => {
  const settings = {
    speed: 65,
    pauseOnHover: true,
    gradient: false
  };

  return (
    <>
      {props.posts.length > 0 &&
        <div className="noticeScroll">
          <div id="lastNotices">DESTAQUES</div>
          <div>
            <Marquee {...settings}>
              {props.posts.map((p,index) => (
                <p key={index}><a href={`/noticia/${p.slug}`}><span className="spanColor">{p.shortdate}</span> {p.title}</a></p>
              ))}
            </Marquee>
          </div>
        </div>
      }
    </>
  );
}

export default SliderHeader;