import React from 'react';

import { Col, Row } from 'react-bootstrap';
import { RiDownloadLine } from 'react-icons/ri';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';

import api from '../../services/api';

export default class MoreImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      images: [],
      page: 0,
      prevY: 0,
      hasMore: true
    };
  }

  componentDidMount() {
    this.getImages();
  }

  getImages = () => {
    this.setState({ loading: true });

    let slug = this.props.match.params.slug;
    
    api(`/getposts.php?fullurl&slug=${slug}`).then(res => {
      let images = [];

      res.data.posts.map(post => {
        post.pics.map(pic => {
          images.push(pic);
        });
      });

      this.setState({ images, loading: false });
    });
    
  }

  render() {
    return (
      <div className="container">
        <Row>
          {this.state.images.map((i,index) => (
            <Col key={index} sm={6} xs={12}>
              <div key={index} className="sliderGallery">
                <img src={i.pic_h} alt="Foto" />
                <div className="downloadImg">
                  <a href={i.fullpic_h} target="_blank" download>
                    <RiDownloadLine />
                    DOWNLOAD
                  </a>
                </div>
                <p><b>Legenda:</b> {`${i.caption}`}</p>
                <p><b>Créditos:</b>{`${i.credits}`}</p>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}  