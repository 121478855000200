import React from 'react';

import { FiShare2 } from 'react-icons/fi';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';

import api from '../../services/api';

export default class Boletim extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 0,
      news: [],
      prevY: 0,
      hasMore: true
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    if(this.state.loading) return;
    this.setState({ loading: true });

    let boletim = this.props.match.params.boletim;

    let start = (11 * this.state.page) + this.state.page;
    let end = start + 11;

    let params = '?fullurl&nolinks';
    params = params + `&start=${start}&end=${end}`;

    if(boletim){
      params = params + `&card=${boletim}`; 
    } else {
      params = params + `&slice=Boletins`; 
    }
    
    api(`/getposts.php${params}`).then(res => {
      let hasMore = res.data.posts.length > 0 ? true : false;
      this.setState({ news: [...this.state.news, ...res.data.posts], hasMore, loading: false, page: this.state.page + 1 });
    });
  }

  render() {
    return (
      <div className="container">
        
          <InfiniteScroll
            dataLength={this.state.news.length} //This is important field to render the next data
            next={this.getNews}
            hasMore={this.state.hasMore}
            loader={<p style={{ textAlign: 'center' }}><b>Carregando...</b></p>}
            refreshFunction={this.getNews}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
          >
            <Row>
            {this.state.news.map((post, index) => (
              <Col key={index} sm={6} xs={12}>
                <div className="boxNotice small">
                  <div className="boxTopNotice">
                    <div className="categoryNotice">
                      <a href={`/boletins/${post.cards[0]}`}><span>{post.cards[0]}</span></a>
                    </div>
                    <div className="dateNotice">
                      <span>{post.shortdate}</span>
                    </div>
                  </div>
                  <a href={post.links.length > 0 ? post.links[0].link : '#'} download target="_blank">
                    <h1>{post.title}</h1>
                  </a>
                  <p>{post.description}</p>
                </div>
                
              </Col>
            ))}
            </Row>
          </InfiniteScroll>
      </div>
    );
  }
}  