import React from 'react';
import { Col, Row } from 'react-bootstrap';

import SliderHeader from '../SliderHeader';

import { FiSearch } from 'react-icons/fi';

import './styles.scss';

import logoHeader from '../../assets/images/logoHeader.webp';
import logoHome from '../../assets/images/logoHome.webp';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      term: '',
    };
  }

  search = () => {
    window.location.href=`/search/${this.state.term}`;
  }
  
  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      this.search();
    }
  }

  render() {
    return (
      <>
      <header>
        <div className="container">
          <Row>
            <Col xs={12} sm>
              <a href="/"><img src={logoHeader} alt="Prefeitura" /></a>
            </Col>
            <Col className="header-col-center">
              <a href="/">
              <h1 className="mediaroom-title">SALA DE IMPRENSA</h1>
              </a>
              <div className="search-bar">
                <input type="text" name="search" onKeyDown={this.handleKeyDown} onChange={(e) => this.setState({ term: e.target.value})} placeholder="Faça uma busca" />
                <button onClick={() => this.search()}><FiSearch /></button>
              </div>
            </Col>
            <Col className="d-none d-sm-block">
              <button id="linkSite" onClick={() => window.location.href='http://www.capital.sp.gov.br/'}><img src={logoHome} alt="Prefeitura" /><p>ACESSE O SITE</p></button>
            </Col>
          </Row>
        </div>
      </header>
      <div className="headerFixed">
        <div className="container">
          <Row>
            <Col className="d-none d-sm-block">
              <a href="/"><img src={logoHeader} alt="Prefeitura" /></a>
            </Col>
            <Col className="header-col-center d-none d-sm-block">
              <a href="/">
                <h1 className="mediaroom-title">SALA DE IMPRENSA</h1>
              </a>
            </Col>
            <Col className="header-col-end">
              <div className="search-bar">
                <input type="text" name="search" onKeyDown={this.handleKeyDown} onChange={(e) => this.setState({ term: e.target.value})} placeholder="Faça uma busca" />
                <button onClick={() => this.search()}><FiSearch /></button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      </>
    );
  }
}  