import React, { useEffect, useState } from 'react';

import { Col, Row } from 'react-bootstrap';

import bannerLogin from '../../assets/images/banner-login.jpg';
import bannerLoginMobile from '../../assets/images/banner-boletim.jpg';

import './styles.css';

import SliderHome from '../../components/SliderHome';
import Boletim from '../../components/Boletim';
import Galeria from '../../components/Galeria';
import Agenda from '../../components/Agenda';
import Midias from '../../components/Midias';
import Videos from '../../components/Videos';
import SliderHeader from '../../components/SliderHeader';
import Featured from '../../components/Featured';
import Firsts from '../../components/Firsts';
import Tag from '../../components/Tag';

import api from '../../services/api';

export const Home = () => {
  const [tops, setTops] = useState([]);
  const [featureds, setFeatureds] = useState([]);
  const [firsts, setFirsts] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [boletins, setBoletins] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [others, setOthers] = useState([]);
  const [videos, setVideos] = useState([]);
  const [medias, setMedias] = useState([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    api('/getposts.php?fullurl&nolinks').then(res => {
      let posts = res.data.posts;
      let toTops = [];
      let toFeatureds = [];
      let toFirsts = [];
      let toSliders = [];
      let toBoletins = [];
      let toGallery = [];
      let toCalendar = [];
      let toMedias = [];
      let toVideos = [];
      let toOthers = [];
      
      posts.map(p => {
        if(p.slice === 'Footer') return;

        if(p.slice === 'Primeira Dobra'){
          if(toFeatureds.length === 0){
            toFeatureds.push(p);
          } else if(toFirsts.length < 6) {
            toFirsts.push(p);
          } else if(toSliders.length < 3) {
            toSliders.push(p);
          }
          return;
        }


        if(p.slice === 'Videos'){
          toVideos.push(p);
          return;
        }

        if(p.slice === 'Mídias Sociais'){
          toMedias.push(p);
          return;
        }

        if(p.slice === 'Boletins'){
          toBoletins.push(p);
          return;
        }

        if(p.slice === 'Galeria'){
          toGallery.push(p);
          return;
        }

        if(p.slice === 'Calendario'){
          toCalendar.push(p);
          return;
        }

        if(p.headline === 1){
          toTops.push(p);
          return;
        }

        if(toOthers.length < 9){
          toOthers.push(p);
        }

      });

      setTops(toTops);
      setFeatureds(toFeatureds);
      setFirsts(toFirsts);
      setSliders(toSliders);
      setBoletins(toBoletins);
      setGallery(toGallery);
      setCalendar(toCalendar);
      setMedias(toMedias);
      setVideos(toVideos);
      setOthers(toOthers);

    });
  }

  return (
    <>
      <SliderHeader posts={tops} />
      <div className="container">
        <Row>
          <Col sm={8}>
            {featureds.length > 0 &&
              <Row>
                <Col sm={12}>
                  <Featured post={featureds[0]} />
                </Col>
              </Row>
            }
            {firsts.length > 0 &&
              <Firsts posts={firsts} />
            }
            
          </Col>
          <Col sm={4}>
            {sliders.length > 0 &&
              <SliderHome posts={sliders} />
            }
          </Col>
        </Row>
        {boletins.length > 0 &&
          <Boletim posts={boletins} />
        }
        <Row>
          <Col sm={6}>
            {gallery.length > 0 &&
              <div id="galeria">
                <Galeria posts={gallery} />
              </div>
            }
            {calendar.length > 0 &&
              <div id="calendar">
                <Agenda posts={calendar} />
              </div>
            }
          </Col>
          <Col sm={6}>
            <div id="otherNews">
              <div className="box-titles">
                  <h1 className="title active">OUTRAS NOTÍCIAS</h1>
              </div>
              <Row>
                {others.map((post, index) => (
                  <Col key={index} sm={12}>
                      <div className="boxNotice small other">
                        <Row>
                          {post.pics.length > 0 &&
                            <Col sm={4}>
                              <a href={`/noticia/${post.slug}`}>
                                <img src={post.pics[0].pic_h} alt={post.pics[0].caption} />
                              </a>
                            </Col>
                          }
                          <Col sm={post.pics.length > 0 ? 8 : 12}>
                            <div className="boxTopNotice">
                              <Tag tags={post.tags} />
                              <div className="dateNotice">
                                <span>{post.shortdate}</span>
                              </div>
                            </div>
                            <a href={`/noticia/${post.slug}`}>
                              <h1>{post.title}</h1>
                            </a>
                            <p>{post.description}</p>
                          </Col>
                        </Row>
                      </div>
                      
                  </Col>
                ))}
                <a className="allNotices" href={`/search`}>TODAS AS NOTÍCIAS</a>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="boxAdvisor">
        <div className="container">
          <Row>
            <Col sm={12}>
              <a href="https://cidadedesaopaulo.i-maxpr.com/"><img className="bannerLogin" src={bannerLogin} alt="Banner Login" /></a>
              <a href="https://cidadedesaopaulo.i-maxpr.com/"><img className="bannerLoginMobile" src={bannerLoginMobile} alt="Banner Login" /></a>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container">
        {medias.length > 0 &&
          <Row>
            <Col sm={12}>
              <Midias posts={medias} />
            </Col>
          </Row>
        }
        {videos.length > 0 &&
          <Row>
            <Col sm={12}>
              <Videos posts={videos} />
            </Col>
          </Row>
        }
      </div>
    </>
  );
}  

export default Home;