import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { RiDownloadLine } from 'react-icons/ri';

import './styles.css';

export const Gallery = ({posts}) => {
  const [pics, setPics] = useState([]);

  useEffect(() => {
    getPics();
  }, []);

  const getPics = () => {
    let toPics = [];

    posts.map(post => {
      post.pics.map(pic => {
        toPics.push({...pic, slug: post.slug});
      });
    });

    setPics(toPics);
  }

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 1500,
    arrows: true,
    pauseOnHover: true
  };

  return (
    <div>
      <div className="box-titles">
          <h1 className="title active">GALERIA</h1>
      </div>
      <div>
        <Slider {...settings}>
          {pics.map((p, index) => (
            <div key={index} className="sliderGallery">
              <img src={p.pic_h} alt={p.caption} />
              <div className="downloadImg">
                <a href={p.fullpic_h} target="_blank" download>
                  <RiDownloadLine />
                  DOWNLOAD
                </a>
                <a href={`/galeria/vermais/${p.slug}`}>
                  VER MAIS
                </a>
              </div>
              <p><b>Legenda:</b> {`${p.caption}`}</p>
              <p><b>Créditos:</b>{`${p.credits}`}</p>
            </div>
          ))}
        </Slider>
      </div>
      <a id="allImages" href="/galeria">TODAS AS IMAGENS</a>
    </div>
  );
}

export default Gallery;