import React from 'react';
import { FiShare2 } from 'react-icons/fi';
import { MdEmail } from 'react-icons/md';
import { FaFacebookF } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { RiWhatsappFill } from 'react-icons/ri';

import './styles.css';

export default class BtnShare extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        //
        };
    }

    shareOnEmail = () => {
        window.open(`mailto:?subject=Notícia&body=${this.props.title}: https://prefeitura.i-max.imaxinformatica.com.br/noticia/${this.props.slug}`, '_blank');
    }
    
    shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=https://prefeitura.i-max.imaxinformatica.com.br/noticia/${this.props.slug}`, '_blank');
    }

    shareOnLinkedin = () => {
        window.open(`https://www.linkedin.com/shareArticle?mini=true&amp;url=https://prefeitura.i-max.imaxinformatica.com.br/noticia/${this.props.slug}`, '_blank');
    }

    shareOnTwitter = () => {
        window.open(`http://twitter.com/intent/tweet?text=${this.props.title}?&amp;url=https://prefeitura.i-max.imaxinformatica.com.br/noticia/${this.props.slug}&amp;via=seunomenotwitter"`, '_blank');
    }

    shareOnWhatsapp = () => {
        window.open(`https://wa.me/?text=${this.props.title}: https://prefeitura.i-max.imaxinformatica.com.br/noticia/${this.props.slug}`, '_blank');
    }

    render() {
        return (
            <div className="socialShareBtns">
                <button onClick={() => this.shareOnEmail()}><MdEmail /></button>
                <button onClick={() => this.shareOnFacebook()}><FaFacebookF /></button>
                <button onClick={() => this.shareOnLinkedin()}><FaLinkedinIn /></button>
                <button onClick={() => this.shareOnTwitter()}><FaTwitter /></button>
                <button onClick={() => this.shareOnWhatsapp()}><RiWhatsappFill /></button>
            </div>
    );
    }
}  