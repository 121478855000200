import React, { useEffect, useState } from 'react';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { GoCalendar } from 'react-icons/go';

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import 'moment/locale/pt-br';

import './styles.css';

export const Agenda = ({posts}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState([]);

  useEffect(() => {
    getEvents();
  }, [,date]);

  const getEvents = () => {
    let formatedDate = moment(date).format('DD/MM/YYYY');
    let toEvents = posts.filter(p => p.title === formatedDate);
    setEvents(toEvents);
  }

  const handleShowCalendar = () => {
    setShowCalendar(!showCalendar);
  }

  const handleChangeDate = date => {
    setShowCalendar(false);
    setDate(date);
  } 
  return (
    <>
    <div className="box-titles">
        <h1 className="title active">AGENDA DO PREFEITO</h1>
    </div>
    <div className="boxScroll">
      <div className="dateSchedule">
        <p>{moment(date).locale('pt-br').format('LL')}</p>
        <button className="showCalendar" onClick={handleShowCalendar}><GoCalendar />VER OUTRA DATA</button>
        {showCalendar &&
          <Calendar className="calendar" value={date} onChange={date => handleChangeDate(date)}  />
        }
      </div>
      {events.map((event,index) => (
        <div key={index} className="boxSchedule">
          <p className="hour">{event.description}</p>
          <p dangerouslySetInnerHTML={{__html: event.contents}} />
        </div>
      ))}
      {events.length === 0 &&
        <p style={{paddingTop: 20}}>Sem compromissos oficiais neste dia.</p>
      }
    </div>
    </>
  );
}

export default Agenda;