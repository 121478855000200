import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import './styles.css';

import { FaFacebookF } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

import logoFooter from '../../assets/images/logoFooter.jpg';
import diarioOficial from '../../assets/images/diarioOficial.png';

import api from '../../services/api';

export const Footer = () => {
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        getPosts();
    }, []);

    const getPosts = () => {
        api('/getposts.php?nolinks&slice=Footer').then(res => {
            let cards = [];
            let toPosts = [];
            res.data.posts.map(p => {
                if(!cards.includes(p.cards[0])){
                    cards.push(p.cards[0]);
                }
            });
            console.log(cards);
            cards.map((card, index) => {
                let toInclude = res.data.posts.filter(p => p.cards.includes(card)); 
                toPosts[index] = {
                    posts: toInclude,
                    card: card
                }
            });
            setPosts(toPosts);
        });
    }

    return (
        <footer>
            <div className="container">
                <Row>
                    {posts.map(post => (
                        <Col sm={3}>
                            <h5>{post.card}</h5>
                            <ul>
                                {post.posts.map(p => (
                                    <li>
                                        <a href={p.links.length > 0 ? p.links[0].link : '#'} target="_blank">{p.title}</a>
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    ))}
                </Row>
                <Row>
                    <Col sm={12}>
                        <div className="midiasFooter">
                            <a href="https://www.imprensaoficial.com.br/" target="_blank"><img src={diarioOficial} alt="Logo Diário Oficial" />Diário Oficial</a>
                            <a href="https://pt-br.facebook.com/PrefSP/" target="_blank"><FaFacebookF /></a>
                            <a href="https://twitter.com/prefsp" target="_blank"><FaTwitter /></a>
                            <a href="https://www.youtube.com/user/prefeiturasaopaulo" target="_blank"><FaYoutube /></a>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="boxLogoCity">
                <div className="container">
                    <Row>
                        <Col sm={12}>
                            <a href="http://www.capital.sp.gov.br/" target="_blank"><img src={logoFooter} alt="Logo Prefeitura" /></a>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="copyright">
                <div className="container">
                    <Row>
                        <Col sm={12}>
                            <p>&copy; COPYRIGHT 2021. Prefeitura Municipal de São Paulo. Viaduto do Chá, 15 - Centro - CEP: 01002-020</p>
                        </Col>
                    </Row> 
                </div>
            </div>
        </footer>
    );
}  

export default Footer;