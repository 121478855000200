import React from 'react';

import Tag from '../Tag';
import PostLink from '../PostLink';

export const Featured = ({post}) => {
    return (
        <div className="boxNotice">
            <div className="boxTopNotice">
                <Tag tags={post.tags} />
                <div className="dateNotice">
                    <span>{post.shortdate}</span>
                </div>
            </div>
            <PostLink post={post}>
                <h1>{post.title}</h1>
            </PostLink>
        </div>
    );
}

export default Featured;