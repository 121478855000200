import React from 'react';

export const Tag = (props) => {
    let tag = props.tags.length > 0 ? props.tags[0] : null;

    if(tag){
        return (
            <div className="categoryNotice">
              <a href={`/tag/${tag}`}><span>{tag}</span></a>
            </div>
            
        );
    } else {
        return (
            <div className="categoryNotice"></div>
        )
    }
}

export default Tag;