import React, { useEffect, useState } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { FaFacebookF } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';

import './styles.css';

export const Midias = ({posts}) => {
  const [tw, setTw] = useState([]);
  const [fb, setFb] = useState([]);
  const [ig, setIg] = useState([]);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = () => {
    let toTw = posts.filter(p => p.cards.includes('tw'));
    let toFb = posts.filter(p => p.cards.includes('fb'));
    let toIg = posts.filter(p => p.cards.includes('ig'));

    setTw(toTw);
    setFb(toFb);
    setIg(toIg);
  }

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const limit = 200;

  return (
    <>
    <div className="box-titles">
        <h1 className="title active">MÍDIAS SOCIAIS</h1>
    </div>
    <div>
      <Slider {...settings} className="boxMidias" >
        {tw.map((post, index) => (
          <div key={index} className="boxSlider sliderMidia">
            {post.pics.length > 0 &&
              <a href={'#'} target="_blank">
                <img src={post.pics[0].pic_h} alt={post.pics[0].caption} />
              </a>
            }
            <a href={'#'} target="_blank"><FaTwitter />/prefsp</a>
            <p className="post">{post.description.substring(0,limit)}</p>
          </div>
        ))}

        {fb.map((post, index) => (
          <div key={index} className="boxSlider sliderMidia">
            {post.pics.length > 0 &&
              <a href={'#'} target="_blank">
                <img src={post.pics[0].pic_h} alt={post.pics[0].caption} />
              </a>
            }
            <a href={'#'} target="_blank"><FaFacebookF />/PrefSP</a>
            <p className="post">{post.description.substring(0,limit)}</p>
          </div>
        ))}

        {ig.map((post, index) => (
          <div key={index} className="boxSlider sliderMidia">
            {post.pics.length > 0 &&
              <a href={'#'} target="_blank">
                <img src={post.pics[0].pic_h} alt={post.pics[0].caption} />
              </a>
            }
            <a href={`#`} target="_blank"><FaInstagram />/prefsp</a>
            <p className="post">{post.description.substring(0,limit)}</p>
          </div>
        ))}
      </Slider>
    </div>
  </>
  );
}  

export default Midias;