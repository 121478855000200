import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import $ from 'jquery';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


// $("#btnSecretary1").click(function(){
//   console.log('clicked');
//   $("#boxScrollSecretary1").toggle(500);
//   $("#iconFa1").toggleClass('flip');
// });

// $("#btnSecretary2").click(function(){
//   $("#boxScrollSecretary2").toggle(500);
//   $("#iconFa2").toggleClass('flip');
// });

// $("#btnSecretary3").click(function(){
//   $("#boxScrollSecretary3").toggle(500);
//   $("#iconFa3").toggleClass('flip');
// });

// $("#btnSecretary4").click(function(){
//   $("#boxScrollSecretary4").toggle(500);
//   $("#iconFa4").toggleClass('flip');
// });

// $("#btnSecretary5").click(function(){
//   $("#boxScrollSecretary5").toggle(500);
//   $("#iconFa5").toggleClass('flip');
// });

// $("#btnSecretary6").click(function(){
//   $("#boxScrollSecretary6").toggle(500);
//   $("#iconFa6").toggleClass('flip');
// });

// $("#btnSecretary7").click(function(){
//   $("#boxScrollSecretary7").toggle(500);
//   $("#iconFa7").toggleClass('flip');
// });

// $("#btnSecretary8").click(function(){
//   $("#boxScrollSecretary8").toggle(500);
//   $("#iconFa8").toggleClass('flip');
// });

$(document).scroll(function() {
  var scrollTop = $(window).scrollTop();
  if (scrollTop > 200 ) {
    $('.headerFixed').css('display', 'block');
    $('.headerFixed').css('transform', 'translateY(0%)');
  }
  else{
    $('.headerFixed').css('display', 'none');
  }
});

// $(".showCalendar").click(function(){
//   $(".react-calendar").toggle(0);
// });