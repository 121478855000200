import React from 'react';

import { FiShare2 } from 'react-icons/fi';
import { Col, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import './styles.css';

import api from '../../services/api';

export default class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      page: 0,
      news: [],
      prevY: 0,
      hasMore: true
    };
  }

  componentDidMount() {
    this.getNews();
  }

  getNews = () => {
    if(this.state.loading) return;
    this.setState({ loading: true });
    let form = new FormData();

    let start = (11 * this.state.page) + this.state.page;
    let end = start + 11;

    console.log(`Page: ${this.state.page}   -  Start: ${start}, End: ${end}`);

    form.append('start', start);
    form.append('end', end);
    if(this.props.match.params.term){
      form.append('search', this.props.match.params.term);
    }
    
    api.post('/getCityHallPostRange.php', form).then(res => {
      let hasMore = res.data.length > 0 ? true : false;
      this.setState({ news: [...this.state.news, ...res.data], hasMore, loading: false, page: this.state.page + 1 });
    });
  }

  render() {
    return (
      <div className="container">
        
          <InfiniteScroll
            dataLength={this.state.news.length} //This is important field to render the next data
            next={this.getNews}
            hasMore={this.state.hasMore}
            loader={<p style={{ textAlign: 'center' }}><b>Carregando...</b></p>}
            refreshFunction={this.getNews}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
          >
            <Row>
            {this.state.news.map((n,index) => (
            <Col key={index} sm={6} xs={12}>
              <div className="boxNotice small">
                <div className="boxTopNotice">
                  <div className="categoryNotice">
                    <a href={`/tag/${n.edt}`}><span>{n.edt}</span></a>
                  </div>
                  <div className="dateNotice">
                    <span>{n.humanpostdate}</span>
                  </div>
                </div>
                <a href={`/noticia/${n.slug}`}>
                  <h1>{n.title}</h1>
                </a>
                <p>{n.desc}</p>
              </div>
              
            </Col>
            ))}
            </Row>
          </InfiniteScroll>
      </div>
    );
  }
}  