import React from 'react';

export const PostLink = (props) => {
    return (
        <a href={`/noticia/${props.post.slug}`}>
            {props.children}
        </a>
    );
}

export default PostLink;