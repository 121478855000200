import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import Select from 'react-select'

import './styles.css';

import { FiShare2 } from 'react-icons/fi';

import api from '../../services/api';

import boletim from '../../assets/images/banner-boletim.jpg';

export const Boletim = (props) => {
  const [posts, setPosts] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState('');
  const [selectedLabel, setSelectedLabel] = useState('Selecione...');

  useEffect(() => {
    setItems();
  }, []);

  useEffect(() => {
    filterPosts();
  }, [selectedValue]);

  const setItems = () => {
    let cards = [];
    let toPosts = [];
    let toOptions = [
      { value: '', label: 'Selecione...' }
    ];

    props.posts.map(post => {
      if(!cards.includes(post.cards[0])){
        toOptions.push({
          value: post.cards[0],
          label: post.cards[0]
        });
        cards.push(post.cards[0]);
      }
    });

    setOptions(toOptions);
    setPosts(toPosts);
  }

  const filterPosts = () => {
    let toPosts = [];
    
    props.posts.map((post, index) => {
      if(index > 5) return;
      
      if(selectedValue === ''){
        toPosts.push(post);
      } else if(post.cards.includes(selectedValue)){
        toPosts.push(post);
      }
    });
    setPosts(toPosts);
  }

  const handleChangeBoletim = (option) => {
    setSelectedValue(option.value);
    setSelectedLabel(option.label);
  }

  return (
    <>
      <Row>
        <Col sm={4}>
          <div className="box-titles">
            <h1 className="title active">BOLETINS</h1>
            <Select
              options={options}
              placeholder="Selecione..."
              value={{value: selectedValue, label: selectedLabel}}
              onChange={(option) => handleChangeBoletim(option)}
            />
          </div>
        </Col>
      </Row>
      <Row> 
          <Col sm={8}>
            <Row>
              {posts.map((post,index) => (
                <Col key={index} sm={6} xs={12}>
                  <div className="boxNotice small boletim">
                    <div className="boxTopNotice">
                      {post.cards.length > 0 &&
                        <div className="categoryNotice">
                          <a href={`/boletins/${post.cards[0]}`}><span>{post.cards[0]}</span></a>
                        </div>
                      }
                      <div className="dateNotice">
                        <span>{post.shortdate}</span>
                      </div>
                    </div>
                    <a href={post.links.length > 0 ? post.links[0].link : '#'} download target="_blank">
                      <h1>{post.title}</h1>
                    </a>
                    <p>{post.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
            <Row>
              <Col>
                <a className="allNotices" href={`/boletins`}>TODOS OS BOLETINS</a>
              </Col>
            </Row>
          </Col>
          <Col sm={4}>
            <a href="https://cidadedesaopaulo.i-maxpr.com/"><img className="imgBoletim" src={boletim} alt="Banner Boletim" /></a>
          </Col>
      </Row>
    </>
  );
}  

export default Boletim;