import React, { useEffect, useState } from 'react';

import { FiShare2 } from 'react-icons/fi';
import { Col, Row } from 'react-bootstrap';

import Tag from '../../components/Tag'

import BtnShare from '../../components/BtnShare';

import './styles.css';

import api from '../../services/api';

import { useParams } from 'react-router';

export const News = () => {
  const [news, setNews] = useState(null);
  const [related, setRelated] = useState([]);

  const { slug } = useParams();

  useEffect(() => {
    getNews();
  }, []);

  useEffect(() => {
    getRelated();
  }, [news]);

  const getNews = () => {
    api(`/getposts.php?fullurl&slug=${slug}`).then(res => {
      if(res.data.posts.length > 0){
        setNews(res.data.posts[0]);
      }
    });
  }

  const getRelated = () => {
    if(news === null) return;

    api(`/getposts.php?fullurl&tag=${news.tags.length > 0 ? news.tags[0] : ''}`).then(res => {
      setRelated(res.data.posts.slice(0, 6));
    });
  }

  return (
    <div className="container">
      {news !== null &&
      
        <div className="content">
          <div className="boxTopNotice">
            <Tag tags={news.tags} />
            <div className="dateNotice">
              <span>{news.shortdate}</span>
            </div>
          </div>
          <h1 className="newsSingleTitle">{news.title}</h1>
          <h2 className="newsSingleSubtitle">{news.description}</h2>

          <BtnShare title={news.title} slug={news.slug} />

          <p dangerouslySetInnerHTML={{__html: news.contents}} />

          <h3 className="titleMoreNews">MAIS NOTÍCIAS</h3>

          <Row>
            {related.map((post, index) => (
              <Col key={index} sm={6} xs={12}>
                <div className="boxNotice small">
                  <div className="boxTopNotice">
                    <Tag tags={post.tags} />
                    <div className="dateNotice">
                      <span>{post.shortdate}</span>
                    </div>
                  </div>
                  <a href={`/noticia/${post.slug}`}>
                    <h1>{post.title}</h1>
                  </a>
                  <p>{post.description}</p>
                </div>
              </Col>
            ))}
          </Row>
          <a href={`/tag/${news.tags[0]}`} className="btnMore">VER TODAS</a>
        </div>
      }
    </div>
  );
}  

export default News;